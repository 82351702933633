import { createSlice } from '@reduxjs/toolkit'
interface TimerState {
  running: boolean
}

const initialState: TimerState = {
  running: false
}

export const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    stop: (state) => state,
    reset: (state) => state
  },
})

export const { stop, reset } = timerSlice.actions

export default timerSlice.reducer
