
import { LocationPaper } from '../../lib/interface';
import { ProgressSegment } from './ProgressSegment';
import { GameContext } from '../../contexts/GameContext';
import { useContext } from 'react';
import { TimerControls } from '../timer/TimerControls';

export function ProgressPanel({ timer }) {
  const game = useContext(GameContext);

  const pos = (locationPaper: LocationPaper) => Math.min(
    ...locationPaper.papers.paper_puzzles
      .map(({ puzzles }) => puzzles)
      .map(({ position }) => position)
  )

  const locationPapers: LocationPaper[] = game.data ? Object.values(game.data.location_papers as LocationPaper[])
    .sort((a, b) => pos(a) - pos(b))
    : []

  return <>
    {locationPapers.map(locationPaper => <ProgressSegment locationPaper={locationPaper} />)}
    <TimerControls timer={timer} fixed="bottom" disabled={true} />
  </>
}
