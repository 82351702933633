import React from "react";
import { Icon, IconGroup } from "semantic-ui-react";

import { AuthenticationContext } from "../contexts/AuthenticationContext";

function UserIcon() {
  const [auth] = React.useContext(AuthenticationContext);
  return (
    <IconGroup >
      <Icon
        name={auth.isFetching ? "spinner" : auth.token ? "user circle" : "user"}
        loading={auth.isFetching}
      />
    </IconGroup>
  );
}

export default UserIcon;
