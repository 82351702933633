import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { statsSlice } from '../stats/statsSlice'
import { timerSlice } from '../timer/timerSlice'
import type { Arrival } from '../../lib/interface'

/** Number of teams for each location paper */

interface LocationPaperCountState {
  location_papers: {}
  teams: {}
}

const initialState: LocationPaperCountState = {
  location_papers: {},
  teams: {}
}

export const locationPaperCountSlice = createSlice({
  name: 'locationPaperCount',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(statsSlice.actions.reached, (state, action: PayloadAction<Arrival>) => {
        const { team_id, location_paper_id, time } = action.payload

        if (state.teams[team_id] !== location_paper_id) {
          if (state.teams[team_id])
            state.location_papers[state.teams[team_id]][team_id] = {
              left: time,
              present: false
            }
          state.teams[team_id] = location_paper_id
          state.location_papers[location_paper_id] ||= {}
          state.location_papers[location_paper_id][team_id] = {
            ...state.location_papers[location_paper_id][team_id],
            reached: time,
            present: true
          }
        }
      })
      .addCase(timerSlice.actions.reset, () => initialState)
  }
})

export default locationPaperCountSlice.reducer
