const group = (result, key, value) => {
  result[key] ||= []
  return result[key].push(value)
}

export function groupBy (arr, f) {
  const result = {}
  for (const x of arr) {
    group(result, f(x), x)
  }
  return result
}

const index = (result, key, value) => (result[key] = value)
export function indexBy (arr, f) {
  const result = {}
  for (const x of arr) {
    index(result, f(x), x)
  }
  return result
}
