import React from "react";

import {
  Container,
  Dimmer,
  Header,
  Segment,
  Icon,
  Button
} from "semantic-ui-react";

export default function ErrorFallback({ error, resetErrorBoundary }) {
  const [active, setActive] = React.useState(true);

  console.warn(error.message);
  console.warn(error.details);

  const handleClose = () => setActive(false);
  const reset = () => {
    setActive(false);
    resetErrorBoundary();
  };

  return (
    <Dimmer active={active} onClickOutside={handleClose} page>
      <Header as="h2" icon inverted>
        <Icon name="warning" />
        Something went wrong
        <Header.Subheader>{error.message}</Header.Subheader>
      </Header>
      <Segment basic>
        <Container text>{error.hint}</Container>
        {error.details && (
          <Container as="pre" textAlign="left">
            {JSON.stringify(error.details, null, 4)}
          </Container>
        )}
        <Button inverted color="red" onClick={reset}>
          Try again
        </Button>
      </Segment>
    </Dimmer>
  );
}
