import { configureStore } from '@reduxjs/toolkit'



import gameReducer from '../features/game/gameSlice'
import favoritesReducer from '../features/favorites/favoritesSlice'
import journalReducer from '../features/journal/journalSlice'
import locationPaperCountReducer from '../features/location_paper_count/locationPaperCountSlice'
import teamLocationsReducer from '../features/team_locations/teamLocationsSlice'
import statsReducer from '../features/stats/statsSlice'

import teamLocationListenerMiddleware from '../features/team_locations/teamLocationListener'
import locationPaperCountListenerMiddleware from '../features/location_paper_count/locationPaperCountListener'
import progressListenerMiddleware from '../features/progress/progressListener'

export const store = configureStore({
  reducer: {
    favorites: favoritesReducer,
    game: gameReducer,
    journal: journalReducer,
    location_paper_count: locationPaperCountReducer,
    stats: statsReducer,
    team_locations: teamLocationsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(teamLocationListenerMiddleware.middleware)
      .prepend(locationPaperCountListenerMiddleware.middleware)
      .prepend(progressListenerMiddleware.middleware)
      ,
      

})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
