import { createListenerMiddleware } from '@reduxjs/toolkit'
import * as d3 from "d3";
import DOMPurify from 'dompurify';
import { colorize } from '../../lib/colors';

import { reached } from '../stats/statsSlice'

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  actionCreator: reached,
  effect: async (action, listenerApi) => {


    const state = (listenerApi.getState() as any)
    const game = state.game.game
    const favoriteTeams = state.favorites.favoriteTeams
    const arrival = action.payload
    const team = game.teams[arrival.team_id]
    if (!arrival.next)
      return

    const svg = d3.select(`#progress-${arrival.location_paper_id}`)

    const start = Date.parse(arrival.time)
    const end = Date.parse(arrival.next.time)
    const duration = (end - start) / 128

    const g = svg.append("g")

    const width = parseInt(svg.style('width'))
    const isFavorite = favoriteTeams.indexOf(arrival.team_id) > -1


    const y = isFavorite ? 61 : 65
    const radius = favoriteTeams.lenght === 0 ? 10 : isFavorite ? 11 : 8
    const opacity = favoriteTeams.length === 0 ? 0.7 : isFavorite ? 0.95 : 0.2
    const strokeWidth = isFavorite ? 8 : 2
    const zIndex = isFavorite ? 99 : "auto"

    g
      .attr("opacity", 1)
      .attr("cx", 50)
      .attr("cy", 50)
      .attr('transform', "translate(" + 20 + "," + y + ")")
      .style("stroke", strokeWidth)
      .style("opacity", opacity)
      .style("z-index", zIndex)
      .transition()
      .duration(300)

    const text = g.append("text")
      .text(DOMPurify.sanitize(team.name));

    if (isFavorite)
      text
        .attr('dy', 24)
        .attr('text-anchor', "middle")
        .attr("font-size", "0.9em")
    else
      text
        .attr('transform', "translate(-13,-3) rotate(25)")
        .attr('text-anchor', "end")
        .attr("font-size", "0.8em")

    g
      .append("circle")
      .attr("r", radius)
      .attr("fill", colorize(arrival.team_id))

    g
      .transition()
      .attr('transform', "translate(" + (width - 50) + "," + y + ")")
      .duration(duration)
      .ease(d3.easeLinear)

    g
      .transition()
      .attr("opacity", 0.1)
      .delay(duration)
      .duration(200)
      .remove()

  }
})

export default listenerMiddleware;
