import { LocationPaper } from "../lib/interface";
import PuzzleDecorator from "./PuzzleDecorator";

export default class LocationPaperDecorator {
  locationPaper: LocationPaper;

  constructor(locationPaper: LocationPaper) {
    this.locationPaper = locationPaper;
  }


  toString() {
    const puzzles = this.locationPaper.papers.paper_puzzles
      .map(({ puzzles }) => puzzles)

    return puzzles.map((puzzle) => new PuzzleDecorator(puzzle).toString()).join(", ")
  }
}
