import { useAppSelector } from '../../lib/hooks'
import { Message, MessageHeader, } from "semantic-ui-react";

import './Journal.css'

export function Journal() {
  const messages = useAppSelector((state) => state.journal.messages)

  return <Message size="tiny" floating className='journal'>
    <MessageHeader>Poslední akce</MessageHeader>
    <p>
      {messages[messages.length - 1]}
    </p>
  </Message>
}
