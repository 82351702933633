import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { loadState, saveState } from '../../lib/local_storage';

interface FavoritesState {
  favoriteTeams: string[]
}

const persistedState = loadState("favorites");

const initialState: FavoritesState = {
  favoriteTeams: persistedState || []
}

export const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    setFavoriteTeams(state, action: PayloadAction<string[]>) {
      state.favoriteTeams = action.payload
      saveState("favorites", state.favoriteTeams)
    },
  },
})

export const { setFavoriteTeams } = favoritesSlice.actions


export default favoritesSlice.reducer
