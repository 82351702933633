import React from "react";
import {
  Button,
  Form,
  Header,
  Message,
  Modal,
  Transition
} from "semantic-ui-react";
import { useForm } from "react-hook-form";

export default function LoginForm({ auth, authenticate, open, gameName }) {
  const { register, unregister, handleSubmit } = useForm();

  const [error, setError] = React.useState(null);

  const onSubmit = credentials => {
    authenticate(gameName, credentials).then(response => {
      if (response.status === 403) {
        if (response.status === 403) {
          setError({
            message: "Váš účet nemá přístup ke hře.",
            details: "Zkontrolujte, že jste mezi organizátory, nebo použijte účet hry."
          });
        } else {
          setError({
            message: "Přihlášení se nepodařilo.",
            details: "Zkontrolujte e-mail a heslo."
          });
        };
      } else {
        setError({
          message: "Přihlášení se nepodařilo.",
          details: "Při komunikaci s serverem došlo k chybě. "
        });
      }
    });
  };

  React.useEffect(() => {
    if (!open) unregister(["password"]);
  }, [open, unregister]);

  return (
    <Modal open={open} as={Form} onSubmit={handleSubmit(onSubmit)}>
      <Header icon="user" content="herka.seslost.cz" />
      <Transition
        visible={error && !auth.isFetching}
        animation="pulse"
        duration={500}
      >
        <Modal.Content>
          <Message
            negative
            header={error && error.message}
            content={error && error.details}
          />
        </Modal.Content>
      </Transition>
      <Modal.Content>
        <Modal.Description>
          <Form.Field>
            <label htmlFor="login">E-mail</label>
            <input {...register("login")} />
          </Form.Field>
          <Form.Field>
            <label htmlFor="password">Heslo</label>
            <input {...register("password")} type="password" />
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button type="submit" primary loading={auth.isFetching}>
          Přihlásit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
