import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Arrival } from '../../lib/interface'

interface StatsState {
  arrivals: Arrival[]
}

const initialState: StatsState = {
  arrivals: [],
}

export const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    reached: (state, action: PayloadAction<Arrival>) => {
      state.arrivals = [...state.arrivals, action.payload]
    },
  },
})

export const { reached } = statsSlice.actions

export default statsSlice.reducer
