
import { Image } from "semantic-ui-react";

function GameLogo({ game, ...props }) {
    if (game.logo_checksum) {
        return (
            <Image
                {...props}
                src={`https://statek.seslost.cz/${game.name}?style=thumb&checksum=${game.logo_checksum}`}
            />);
    } else if (game.game_series?.logo_checksum) {
        return (
            <Image
                {...props}
                src={`https://statek.seslost.cz/sifrovacky/${game.game_series.slug}?style=thumb&checksum=${game.game_series.logo_checksum}`}
            />
        )
    } else {
        return null
    }
}

export default GameLogo;
