import { PostgrestClient, PostgrestResponse } from "@supabase/postgrest-js";
import { Dispatch } from "react";

export function api(token: string, schema: string) {
  const location = window.location;
  const protocol = location.protocol;
  const hostname = location.hostname.replace("tele", "api");
  const port = location.port ? ":6430" : "";
  const url = `${protocol}//${hostname}${port}`;
  const client = new PostgrestClient(url, { schema });
  if (token) client.auth(token);
  return client;
}

export type Data<Val> = {
  didInvalidate: boolean;
  isFetching: boolean;
  data: Val | null;
  lastUpdated: number | null;
};

export function handleResponse(
  handleError: React.Dispatch<React.SetStateAction<any | null>>,
  onSuccess = null
) {
  return (response: PostgrestResponse<any>) => {
    if (response.error) {
      handleError(response.error);
    } else {
      if (onSuccess !== null) onSuccess(response.data);
    }
  };
}

export function handleMaybeSingle(
  handleError: React.Dispatch<React.SetStateAction<any | null>>,
  onSuccess = null
) {
  return (response: PostgrestResponse<any>) => {
    if (response.error) {
      // PGRST116 is returned is result contains zero results
      if (response.error.code !== "PGRST116") {
        handleError(response.error);
      }
    } else {
      if (onSuccess !== null) onSuccess(response.data);
    }
  };
}


export function handleData(
  handleError: React.Dispatch<React.SetStateAction<any | null>>,
  setData: Dispatch<Data<any>>,
  transform = null
) {
  return (response: PostgrestResponse<any>) => {
    if (response.error) {
      receiveError(setData);
      handleError(JSON.stringify(response.error, null, 4));
    } else {
      if (transform) receive(transform(response.data), setData);
      else receive(response.data, setData);
    }
  };
}

export function initialState(initialValue = null) {
  return {
    didInvalidate: true,
    isFetching: false,
    data: initialValue,
    lastUpdated: null,
  };
}

export function request(setData: Dispatch<any>) {
  setData((data: Data<any>) => {
    console.log("Request", data);
    return {
      ...data,
      didInvalidate: true,
      isFetching: true,
    };
  });
}

export function receive(newData: any, setData: Dispatch<any>) {
  setData(() => {
    console.log("Receive", newData);
    return {
      didInvalidate: false,
      isFetching: false,
      data: newData,
      lastUpdated: new Date(),
    };
  });
}

export function invalidate(setData: (data: any) => void, message = null) {
  setData((data: Data<any>) => {
    console.log("Invalidate", message, data.data);
    return {
      ...data,
      didInvalidate: true,
    };
  });
}

export function clear(setData: (data: any) => void) {
  setData((data: Data<any>) => {
    console.log("Clear", data.data);
    return {
      data: null,
      didInvalidate: false,
      isFetching: false,
      lastUpdated: new Date(),
    };
  });
}

export function receiveError(setData: Dispatch<any>) {
  setData((data: Data<any>) => {
    console.log("Receive error", data);
    return {
      didInvalidate: false,
      isFetching: false,
      data: null,
      lastUpdated: new Date(),
    };
  });
}
