import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { statsSlice } from '../stats/statsSlice'
import { timerSlice } from '../timer/timerSlice'
import type { Arrival } from '../../lib/interface'

interface TeamLocationsState {
  teams: {}
}

const initialState: TeamLocationsState = {
  teams: {}
}

export const locationPaperCountSlice = createSlice({
  name: 'teamLocations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(statsSlice.actions.reached, (state, action: PayloadAction<Arrival>) => {
        const { team_id, location_paper_id } = action.payload
        state.teams[team_id] = location_paper_id
      })
      .addCase(timerSlice.actions.reset, () => initialState)
  }
})

export default locationPaperCountSlice.reducer
