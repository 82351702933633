import React from "react";

import { AuthenticationContext } from "../contexts/AuthenticationContext";
import { GameContext } from "../contexts/GameContext";
import { useErrorHandler } from "react-error-boundary";
import { Link, NavLink, Route, Routes } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Menu, Icon } from "semantic-ui-react";

import UserIcon from "./UserIcon";

import { q } from "../lib/Query";
import { api, handleData, handleResponse, initialState, receive } from "../lib/api";
import { useAppDispatch } from '../lib/hooks'
import { setGame as setGameState } from '../features/game/gameSlice'
import GamePanel from "./GamePanel";
import GameLogo from "./GameLogo";
import { indexBy } from "../lib/collection";
import { Arrival, Game } from "../lib/interface";
import { ProgressPanel } from "../features/progress/ProgressPanel";
import Timer from "../lib/timer";
import { reached } from "../features/stats/statsSlice";
import FavoritesModalForm from "../features/favorites/FavoritesModalForm";

function GameBoard() {
  const { gameName } = useParams();

  const [game, setGame] = React.useState(initialState());

  const [auth, setShowLoginForm] = React.useContext(AuthenticationContext);
  const dispatch = useAppDispatch()
  const handleError = useErrorHandler();

  const [arrivals, setArrivals] = React.useState(initialState([]));
  const [timer] = React.useState(new Timer());
  const [favoritesModalOpened, setShowFavoritesForm] = React.useState(false);



  React.useEffect(() => {
    if (!gameName) return;
    // if (auth.didInvalidate) return;
    // if (auth.isFetching) return;
    if (!game.didInvalidate) return;
    if (game.isFetching) return;
    api(auth?.token, "games")
      .from("games")
      .select(
        q([
          "game_id",
          "game_series_id",
          "title",
          "name",
          "start",
          "end",
          "volume",
          { "game_series": ["game_series_id", "name", "slug", { "...game_series_checksums": ["logo_checksum"] }] },
          { "...game_checksums": ["logo_checksum"] },
        ])
      )
      .eq("name", gameName)
      .single()
      .then(handleResponse(handleError, (game: Game) => {
        const queries = {
          puzzles: {
            puzzles: ["id:puzzle_id", "name", "position", "tag"],
            locations: ["id:location_id", "name", "lat:latitude", "lng:longitude"],
            location_papers: ["id:location_paper_id", "location_id", "paper_id", {
              papers: ["id:paper_id", "code", "final",
                {
                  paper_puzzles: ["id:paper_puzzle_id", "paper_id", "puzzle_id",
                    { puzzles: ["id:puzzle_id", "name", "position", "tag"] }
                  ],
                },
              ]
            }],
          },
          games: {
            teams: ["id:team_id", "name"],
          }
        }

        const tables = Object.values(queries).flatMap(items => Object.keys(items))
        const requests = Object.entries(queries).flatMap(([schema, items]) =>
          Object.entries(items)
            .map(([table, query]) =>
              api(auth?.token, schema)
                .from(table)
                .select(q(query))
                .eq("game_id", game.game_id))
        )
        Promise.all(requests).then(responses => {
          responses.forEach((response, i) => handleResponse(handleError, (values: []) => {
            game[tables[i]] = indexBy(values, ({ id }) => id)
          })(response))

          receive(game, setGame)
          dispatch(setGameState(game))
        })
      }), handleError)
  }, [gameName, auth, dispatch, game, handleError]);


  React.useEffect(() => {
    if (game.didInvalidate) return;
    if (game.isFetching) return;
    if (!arrivals.didInvalidate) return;
    if (arrivals.isFetching) return;

    const start = new Date(Date.parse(game.data.start))
    const end = new Date(Date.parse(game.data.end))

    timer.setStart(start)
    timer.setEnd(end)
    timer.run()

    api(auth?.token, "stats")
      .from("arrivals")
      .select(
        q([
          "id:arrival_id",
          "team_id",
          "location_paper_id",
          "time",
          { "teams!inner": ["team_id", "game_id", "name"] },
        ]),
      )
      .eq("teams.game_id", game.data.game_id)
      .order('time')
      .then(handleData(handleError, setArrivals, (arrivals: Arrival[]) => {
        const prevArrivals = {}
        arrivals.forEach((arrival: Arrival) => {
          const { team_id } = arrival
          const prev = prevArrivals[team_id]
          if (prev) {
            prev.next = arrival
          }
          prevArrivals[team_id] = arrival
        })
        return arrivals
      }), handleError);
  }, [game, timer, auth, arrivals, handleError]);

  React.useEffect(() => {
    if (!game) return;
    if (arrivals.didInvalidate) return;
    if (arrivals.isFetching) return;

    arrivals.data.filter(({ time }) => time).forEach((arrival: Arrival) => {
      const time = new Date(Date.parse(arrival.time))
      timer.setTrigger(arrival, () => dispatch(reached(arrival)), time)
    })
  }, [game, arrivals, timer, dispatch]);


  return (
    <>
      <Menu attached={"top"} inverted={true}>
        <Menu.Item as={NavLink} icon name="house" to="../">
          <Icon name="home" />
        </Menu.Item>
        {game.data && <Menu.Item>
          <GameLogo game={game.data} height={20} />
          <Link to={""}>{game.data?.title}</Link>
        </Menu.Item>}
        <Menu.Item as={NavLink} icon name="globe" to="./mapa">
          <Icon name="globe" />
        </Menu.Item>
        <Menu.Item as={NavLink} icon name="progress" to="./bar">
          <Icon name="bars" />
        </Menu.Item>
        <Menu.Item position="right" icon link onClick={() => setShowFavoritesForm(true)}>
          <Icon name="star" />
        </Menu.Item>
        <Menu.Item icon link onClick={() => setShowLoginForm(true)}>
          <UserIcon />
        </Menu.Item>
      </Menu>
      <GameContext.Provider value={game}>
        <FavoritesModalForm open={favoritesModalOpened} setOpen={setShowFavoritesForm} />
        <Routes>
          <Route
            path="/bar"
            element={<ProgressPanel timer={timer} />}
          />
          <Route
            path="/mapa"
            element={<GamePanel timer={timer} />}
          />
          <Route
            path="*"
            element={<GamePanel timer={timer} />}
          />
        </Routes>


      </GameContext.Provider>
    </>
  );
}

export default GameBoard;
