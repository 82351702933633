import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Game } from '../../lib/interface'

interface GameState {
  game: Game | undefined
}

const initialState: GameState = {
  game: undefined
}

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setGame: (state, action: PayloadAction<Game>) => {
      state.game = action.payload
    },
  },
})

export const { setGame } = gameSlice.actions

export default gameSlice.reducer
