
import { Segment, } from "semantic-ui-react";

import './ProgressSegment.css'
import LocationPaperDecorator from '../../decorators/LocationPaperDecorator';

export function ProgressSegment({ locationPaper }) {

  return <Segment attached compact padded className="progress-segment">
    <h3 >
      {new LocationPaperDecorator(locationPaper).toString()}
    </h3>
    <svg id={`progress-${locationPaper.id}`}  />
  </Segment>
}
