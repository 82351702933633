import React from "react";
import { Journal } from "../features/journal/Journal";
import { Map } from "../features/map/Map";
import { GameContext } from "../contexts/GameContext";
import { TimerControls } from "../features/timer/TimerControls";
import { Dimmer, Loader } from "semantic-ui-react";
import { LocationPaperCount } from "../features/location_paper_count/LocationPaperCount";

function GamePanel({ timer }) {
  const game = React.useContext(GameContext);
  const [locationPaper, setLocationPaper] = React.useState(null)

  return (
    <>
      <Dimmer active={game.isFetching}>
        <Loader />
      </Dimmer>
      <Journal />
      <LocationPaperCount locationPaper={locationPaper} setLocationPaper={setLocationPaper} />
      <Map setLocationPaper={setLocationPaper} />
      <TimerControls timer={timer} attached="bottom" />
    </>
  );
}

export default GamePanel;


