import { Tooltip, CircleMarker, LayerGroup, MapContainer, TileLayer } from "react-leaflet";

import './Map.css'
import { GameContext } from "../../contexts/GameContext";
import { Location, LocationPaper } from "../../lib/interface";
import React, { useEffect, useState } from "react";
import { useAppSelector } from '../../lib/hooks'

export function Map({ setLocationPaper }) {
  const center = { lat: 50.073658, lng: 14.418540 }
  const zoom = 13
  const [map, setMap] = useState(null)

  const game = React.useContext(GameContext);

  const locationPapers = game.data ? Object
    .values(game.data.location_papers as LocationPaper[])
    : []

  useEffect(() => {
    if (!map) return

    const locations = game.data ? Object.values(game.data.locations as Location[]).filter(({ lat }) => lat) : []
    if (locations.length === 0) return
    map.fitBounds(locations)
  }, [game, map])

  const counts = useAppSelector((state) => state.location_paper_count.location_papers)

  return (
    <div id='map'>
      <MapContainer
        center={center}
        zoom={zoom}
        ref={setMap}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LayerGroup>
          {locationPapers.map((locationPaper) => {
            const { id } = locationPaper

            const teams = counts[id] ? Object.keys(counts[id])
              .filter(key => counts[id][key]?.present)
              .map(key => game.data.teams[key])
              : []
            const radius = (8 + teams.length)
            const color = counts[id] ? "darkgreen" : "black"

            const location = game.data.locations[locationPaper.location_id]
            return <CircleMarker
              key={"location-paper-" + id}
              className={counts[id] && "visited"}
              center={location}
              radius={radius}
              eventHandlers={{ click: () => setLocationPaper(locationPaper) }}
              pathOptions={{
                color: color,
                fill: true,
                fillColor: color,
                fillOpacity: 0.2,
                opacity: 0.5,
                stroke: true,
                weight: 1,
              }}
            >
              <Tooltip >
                {teams.map(({ name }) => name).join(", ")}
              </Tooltip>
            </CircleMarker>
              ;
          }
          )}
        </LayerGroup>
      </MapContainer>

    </div>
  );
};
