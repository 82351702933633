import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { Card, Dimmer, Loader, Segment } from "semantic-ui-react";

import GameCard from "./GameCard";
import { q } from "../../lib/Query";
import { api, handleData, initialState } from "../../lib/api";
import { Game } from "../../lib/interface";

function DashBoard() {
  const [games, setGames] = React.useState(initialState());

  const handleError = useErrorHandler();

  React.useEffect(() => {
    api(null, "games")
      .from("games")
      .select(q([
        "game_id", "name", "title", "city", "volume", "start", "end",
        { "game_series": ["game_series_id", "name", "slug", { "...game_series_checksums": ["logo_checksum"] }] },
        { "...game_checksums": ["logo_checksum"] },
      ]))
      .eq('show_statistics', true)
      .order("start", { ascending: false })
      .then(handleData(handleError, setGames), handleError);
  }, [handleError]);

  return (
    <Segment basic attached >
      <Dimmer active={games.isFetching}>
        <Loader></Loader>
      </Dimmer>
      <Card.Group centered doubling>
        {games.data?.map((game: Game) => {
          return (
            <GameCard
              key={game.game_id}
              game={game}
            />
          );
        })}
      </Card.Group>
    </Segment>
  );
}

export default DashBoard;
