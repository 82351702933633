export default class Query {
  joins: Map<string, Query>;
  select: Set<string>;

  constructor() {
    this.select = new Set();
    this.joins = new Map();
  }

  merge(param: Object) {
    if (Array.isArray(param)) {
      param.forEach((p) => this.merge(p));
    } else if (typeof param == "object") {
      for (const key in param) {
        if (!this.joins.has(key)) this.joins.set(key, new Query());
        this.joins.get(key).merge(param[key]);
      }
    } else {
      this.select.add(param);
    }
    return this;
  }

  isEmpty() {
    return this.select.size === 0 && this.joins.size === 0;
  }

  toString() {
    return Array.from(this.select)
      .concat(
        Array.from(this.joins.keys()).map(
          (key) => `${key}(${this.joins.get(key).toString()})`
        )
      )
      .join(",");
  }
}

export const q = (param: {}) => new Query().merge(param).toString();
