
import { cs } from "date-fns/locale";
import { format } from "date-fns";
import { List } from "semantic-ui-react";

function GameAttributes({ game }): JSX.Element {

  const startFormat = (({ start, start_precision }) => {
    if (!start)
      return null

    if (start_precision === 'exact')
      return "eeee d. MMMM yyyy"
    if (start_precision === 'day')
      return "eeee d. MMMM yyyy"
    if (start_precision === 'month')
      return "MMMM yyyy"
    return "yyyy"
  })(game)

  const simpleUrl = (({ web_pages }) => {
    if (!web_pages)
      return null

    try {
      const url = new URL(game.web_pages);
      return url.origin
    } catch {
      console.log('Could not parse URL', game.web_pages)
      return null
    }
  })(game)

  return (
    <List>
      {game.start && (
        <List.Item>
          <List.Icon name="calendar" />
          <List.Content>
            <List.Description>
              {format(Date.parse(game.start), startFormat, { locale: cs })}
            </List.Description>
          </List.Content>
        </List.Item>
      )}
      {game.start && game.end && game.start_precision === "exact" && (
        <List.Item>
          <List.Icon name="time" />
          <List.Content>
            <List.Description>
              {format(Date.parse(game.start), "HH:mm", {
                locale: cs,
              })}{" "}
              —
              {format(Date.parse(game.end), "HH:mm", {
                locale: cs,
              })}
            </List.Description>
          </List.Content>
        </List.Item>
      )}
      {game.city && (
        <List.Item>
          <List.Icon name="marker" />
          <List.Content>
            <List.Description>{game.city}</List.Description>
          </List.Content>
        </List.Item>
      )}
      {game.num_teams !== undefined && game.num_teams > 0 && (
        <List.Item>
          <List.Icon name="group" />
          <List.Content>
            <List.Description>{game.num_teams} týmů</List.Description>
          </List.Content>
        </List.Item>
      )}
      {game.num_puzzles !== undefined && game.num_puzzles > 0 && (
        <List.Item>
          <List.Icon name="puzzle" />
          <List.Content>
            <List.Description>{game.num_puzzles} šifer</List.Description>
          </List.Content>
        </List.Item>
      )}
      {game.web_pages && (
        <List.Item>
          <List.Icon name="globe" />
          <List.Content>
            <List.Description><a href={game.web_pages}>{simpleUrl}</a></List.Description>
          </List.Content>
        </List.Item>
      )}
    </List>
  );
}

export default GameAttributes;
