import { Puzzle } from "../lib/interface";

export default class PuzzleDecorator {
  puzzle: Puzzle;

  constructor(puzzle: Puzzle) {
    this.puzzle = puzzle;
  }

  name() {
    return this.puzzle.name;
  }

  toString() {
    return [this.label(), this.name()].filter((o) => o).join(" — ");
  }
  label() {
    return [this.puzzle.position, this.puzzle.tag].filter((o) => o).join("");
  }
}
