import React from "react";
import EditMode from "./EditMode";
import { useParams } from "react-router-dom";
import LoginForm from "./LoginForm";
import { AuthenticationContext } from "../contexts/AuthenticationContext";

const initialState = {
  isFetching: false,
  didInvalidate: true,
  token: null,
  lastUpdated: null,
};

function Authentication() {
  const [auth, setAuthentication] = React.useState(initialState);
  const [showLoginForm, setShowLoginForm] = React.useState(false)
  const { gameName } = useParams();

  const requestAuthToken = () => {
    setAuthentication({
      isFetching: true,
      didInvalidate: false,
      token: null,
      lastUpdated: null,
    });
  };

  const receiveAuthToken = (token: string) => {
    setAuthentication({
      isFetching: false,
      didInvalidate: false,
      token: token,
      lastUpdated: Date.now(),
    });
    setShowLoginForm(false)
  };

  const receiveAuthTokenFailure = (error: string) => {
    console.warn(error);
    setAuthentication({
      isFetching: false,
      didInvalidate: false,
      token: null,
      lastUpdated: Date.now(),
    });
  };

  const authenticate = async (gameName: string, { login, password }) => {
    requestAuthToken();
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Content-Profile": "authentication"
    };

    try {
      const response = await fetch(
        'https://api.seslost.cz/rpc/token',
        {
          method: "POST",
          headers: new Headers(headers),
          body: JSON.stringify({
            game_name: gameName,
            login,
            password: password
          }),
        }
      );
      if (response.ok) {
        response.json().then(({ token }) => receiveAuthToken(token));
      } else {
        response.text().then((err) => {
          receiveAuthTokenFailure(err);
        });
      }
      return response;
    } catch (error) {
      receiveAuthTokenFailure(error);
      return error;
    }
  };


//   React.useEffect(() => {
//     const authenticateUsingCookies = async (gameName: string) => {
//       requestAuthToken();
//       let headers = {
//         Accept: "application/json",
//       };
//       try {
//         const response = await fetch(
//           `https://trakar.seslost.cz/org/${gameName}/auth`,
//           {
//             method: "POST",
//             mode: "cors",
//             credentials: "include",
//             headers: new Headers(headers),
//           }
//         );
//         if (response.ok) {
//           response.text().then((token) => receiveAuthToken(token));
//         } else {
//           response.text().then((err) => {
//             receiveAuthTokenFailure(err);
//           });
//         }
//         return response;
//       } catch (error) {
//         receiveAuthTokenFailure(error);
//         return error;
//       }
//     };
// 
//     if (!gameName) return
//     authenticateUsingCookies(gameName);
//   }, [gameName]);

  return (
    <AuthenticationContext.Provider value={[auth, setShowLoginForm]}>
      <LoginForm
        authenticate={authenticate}
        auth={auth}
        open={showLoginForm}
        gameName={gameName}
      />
      <EditMode />
    </AuthenticationContext.Provider>
  );
}

export default Authentication;
