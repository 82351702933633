import { SemanticCOLORS } from "semantic-ui-react";
import { Uuid25 } from "uuid25";

export const colors: SemanticCOLORS[] = [
  "red",
  "green",
  "teal",
  "blue",
  "violet",
  "pink",
  "brown",
  "purple",
  "grey",
  "orange",
  "olive"
];

export const colorize = uuid => {
  return colors[colorIndex(uuid)];
};

export const color = (i: number) => colors[i % colors.length]
export const colorIndex = (uuid) => {

  const hash = Uuid25.parse(uuid).toBytes().reduce((a: number, b: number) => a + b, 0);
  return hash % colors.length
}

