import { useContext } from "react";
import { Button, Segment } from "semantic-ui-react";

import { GameContext } from "../../contexts/GameContext";
import { LocationPaper, Team } from "../../lib/interface";

import './LocationPaperCount.css'

export function LocationPaperCount({ locationPaper, setLocationPaper }) {
  const game = useContext(GameContext);

  const locationPapers: LocationPaper[] = game.data ? Object.values(game.data.location_papers) : []
  const teams: Team[] = game.data ? Object.values(game.data.teams) : []

  // set the dimensions and margins of the graph
  const margin = { top: 30, right: 30, bottom: 70, left: 60 }
  const width = 300 - margin.left - margin.right
  const height = teams.length * 18 - margin.top - margin.bottom;

  return < Segment className='location-paper' style={{ display: locationPaper ? 'block' : 'none' }}>
    <Button icon="close" size="small" floated="right" onClick={() => setLocationPaper(null)} />
    {locationPapers.map(({ id, location_id }) =>
      <div key={`arrivals-${id}`} style={{ display: id === locationPaper?.id ? 'block' : 'none' }}>
        <h3>{game.data?.locations[location_id].name}</h3>
        <svg id={`location-paper-${id}`} height={height} width={width} />
      </div>)}
  </Segment >
}
