import "semantic-ui-css/semantic.min.css";

import "./App.css";

import { Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import ErrorFallback from "../lib/ErrorFallback";

import Authentication from "./Authentication";
import DashBoard from "../features/dashboard/DashBoard";

function App() {

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Routes>
          <Route path="/:gameName/*" element={<Authentication />} />
          <Route
            path="*"
            element={<DashBoard />}
          />
        </Routes>
      </ErrorBoundary>
    </>
  );
}

export default App;
