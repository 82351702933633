import { useContext } from "react";
import Select from "react-select";
import { Icon, Modal } from "semantic-ui-react";

import { GameContext } from "../../contexts/GameContext";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { setFavoriteTeams } from "../favorites/favoritesSlice";

import { Team } from "../../lib/interface";


export default function FavoritesModalForm({ open, setOpen }) {
  const favoriteTeams = useAppSelector((state) => state.favorites.favoriteTeams)
  const dispatch = useAppDispatch()
  const game = useContext(GameContext);
  const teams = game.data ? Object.values(game.data?.teams) : []

  const options = teams
    .sort((a: Team, b: Team) => a.name.localeCompare(b.name))
    .map(({ id, name }: Team) => ({
      value: id,
      label: name,
    })) as any

  const selectedOptions = favoriteTeams
    .map(id => ({
      value: id,
      label: game.data?.teams[id]?.name
    })) as any

  const onChange = (selected: any) => dispatch(setFavoriteTeams(selected.map(({ value }) => value)))
  const onClose = () => setOpen(false)

  return (
    <Modal open={open} size="small" closeOnEscape onClose={onClose}>
      <Modal.Header icon>
        <Icon icon="group" />
        Zvýrazněné týmy
      </Modal.Header>
      <Modal.Content >
        <Modal.Description>
          <Select
            options={options}
            closeMenuOnSelect={false}
            onChange={onChange}
            value={selectedOptions}
            isClearable
            isMulti
            isSearchable
            placeholder="Hledat…"
          />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}
