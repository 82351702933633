import { Link } from "react-router-dom";

import { Card } from "semantic-ui-react";
import GameAttributes from "./GameAttributes";
import GameLogo from "../../components/GameLogo";

function GameCard({ game }): JSX.Element {
  return (
    <Card >
      <Card.Content>
        <Card.Header>
          <GameLogo game={game}
            floated="right"
            height="30"
            wrapped
            ui={false}
          />
          <Link to={`/${game.name}`}>{game.title}</Link>
        </Card.Header>
        <Card.Meta>{game.volume}. ročník</Card.Meta>
        <Card.Description>
          <GameAttributes game={game} />
          {game.description}
        </Card.Description>
      </Card.Content>
    </Card>
  );
}

export default GameCard;

